input::placeholder{
    color:#94C1FF !important;
    opacity: 1;
}

.spinner { 
    width: 40px; 
    height: 40px; 
    border: 4px solid rgba(0, 0, 0, 0.1); 
    border-top: 4px solid blue; 
    border-radius: 50%; 
    animation: spin 1s linear infinite; 
} 

.file-input {
    position: relative;
    display: inline-block;
  }

.file-input input[type="file"] {
    opacity: 0;
    position: absolute;
    z-index: 2;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.file-input::before {
    content: "Update if necessary";
    display: block;
    position: absolute;
    border-radius: 10px;
    top: 1;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;
    background: #F9FAFB;
    border: 1px solid green;
    color: #666;
    text-align: left;
    pointer-events: none;
    color:rgb(219, 3, 3);
}


.py-for-upload-icon{
    padding-top: 2.5px;
    padding-bottom: 2.5px;
}

@keyframes spin { 
    0% { 
        transform: rotate(0deg); 
    } 
    100% { 
        transform: rotate(360deg); 
    } 
}